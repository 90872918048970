<template>
  <div id="accountBank">
    <div v-if="loaded">
      <!-- BANK TABLE -->
      <b-table
          ref="bankDetails-table"
          striped
          hover
          responsive
          v-bind:items="currentBankList"
          v-bind:fields="fields"
          :busy="!loaded"
          @row-clicked="onRowClicked">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mx-1"></b-spinner>
            <strong>{{ msg('Loading') }}...</strong>
          </div>
        </template>

      </b-table>

    </div>
    <div v-else-if="this.error != ''">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <b-spinner label="Spinning"/>
      {{ msg('loading') }}
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'rejectedProjects',
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
    historyMaxHeight: {
      default: 500,
      type: Number
    },
    disableAddBank: {
      required: false,
      type: Boolean
    }
  },
  watch: {},
  data: () => ({
    loaded: false,
    error: '',
    bankList: [],
    currentBankList: [],
    //Listeners
    listeners: {
      load: () => {
      }
    }
  }),

  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    ...mapGetters('data', ['getUserFullName', 'getUsersByRole', 'getAllUsers', 'getUser']),
    ...mapGetters('building', ['fileTags']),

    editableFields() {
      return this.fields.filter(field => field.editable)
    },
    bank: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    fields() {
      let $this = this
      return [

        {
          key: 'company',
          label: $this.msg('Company'),
          sortable: true
          , editable: false
        },

      ]
    },

  },
  created() {

    this.currentBankList = this.bank.rejectedProjects;
    this.loaded = true;

  },
  beforeDestroy() {
    //  this.$root.$off('crm::clientId::bank::load', this.listeners.load)
  },
  methods: {
    ...mapActions("document", ["downloadCustomFile"]),
    ...mapActions('building', ['setBankToBuilding', 'updateBankDocumentFile']),
    ...mapActions('bank', {
      _loadBankList: 'getAllBanks'
    }),

    onValueUpdated(args) {

      this.$emit('change', args)
    },
    onRowClicked(item) {

    },

    doNothing(bankId) {
    },

    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    onRowClick(order) {
      this.$emit("rowClicked", order)
    },
    reset() {
      this.loaded = false
      this.error = ''

    },

    dismissPopover() {
      this.$root.$emit('bv::hide::popover')
    },


  }
}
</script>

<style scoped>

</style>
