<template>
  <div id="accountBank">
    <div v-if="loaded">
      <!-- BANK TABLE -->
      <b-table
          ref="bankDetails-table"
          striped
          hover
          responsive
          v-bind:items="currentBankList"
          v-bind:fields="fields"
          :busy="!loaded"
          @row-clicked="onRowClicked">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mx-1"></b-spinner>
            <strong>{{ msg('Loading') }}...</strong>
          </div>
        </template>
        <template #cell(monthlyPayment)="data">
          {{ convertToEURO(data.value) }}
        </template>
        <template #cell(originalMortgage)="data">
          {{ convertToEURO(data.value) }}
        </template>
        <template #cell(interestRate)="data">
          {{ data.value }}%
        </template>
        <template #cell(creditStart)="data">
          {{ $moment(data.value, 'YYYY-MM-DD').format("DD/MM/YYYY") }}
        </template>
        <template #cell(creditEnd)="data">
          {{ $moment(data.value, 'YYYY-MM-DD').format("DD/MM/YYYY") }}
        </template>
        <template slot="bottom-row" slot-scope="data">
          <td v-for="(field, i)  in data.fields" v-bind:key="i">
            <b-badge variant="danger"  v-if="i===2"> {{ total }}</b-badge>
            <span v-if="i===0" style="font-weight: bold"> TOTAL</span>
          </td>
        </template>

      </b-table>

    </div>
    <div v-else-if="this.error != ''">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <b-spinner label="Spinning"/>
      {{ msg('loading') }}
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'bankMortgages',
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
    historyMaxHeight: {
      default: 500,
      type: Number
    },
    disableAddBank: {
      required: false,
      type: Boolean
    }
  },
  watch: {},
  data: () => ({
    loaded: false,
    error: '',
    bankList: [],
    currentBankList: [],
    //Listeners
    listeners: {
      load: () => {
      }
    }
  }),

  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    ...mapGetters('data', ['getUserFullName', 'getUsersByRole', 'getAllUsers', 'getUser']),
    ...mapGetters('building', ['fileTags']),

    editableFields() {
      return this.fields.filter(field => field.editable)
    },
    bank: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    total() {
      return this.convertToEURO(this.bank.buildingMortgages.reduce((total ,obj) => obj.originalMortgage + total, 0));
    },
    fields() {
      let $this = this
      return [

        {
          key: 'company',
          label: $this.msg('Company'),
          sortable: true
          , editable: false
        },
        {
          key: 'address',
          label: $this.msg('Address'),
          sortable: true
          , editable: false
        },
        {
          key: 'originalMortgage',
          label: $this.msg('Volume'),
          sortable: true
          , editable: true

        },

        {
          key: 'interestRate',
          label: $this.msg('Commission'),
          sortable: true, editable: true

        },

        {
          key: 'creditStart',
          label: $this.msg('Start'),
          sortable: true
        },
        {
          key: 'creditEnd',
          label: $this.msg('End'),
          sortable: true
        },

      ]
    },

  },
  created() {

    this.currentBankList = this.bank.buildingMortgages;
    this.loaded = true;

  },
  beforeDestroy() {
    //  this.$root.$off('crm::clientId::bank::load', this.listeners.load)
  },
  methods: {
    ...mapActions("document", ["downloadCustomFile"]),
    ...mapActions('building', ['setBankToBuilding', 'updateBankDocumentFile']),
    ...mapActions('bank', {
      _loadBankList: 'getAllBanks'
    }),

    onValueUpdated(args) {

      this.$emit('change', args)
    },
    onRowClicked(item) {

    },

    doNothing(bankId) {
    },

    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    onRowClick(order) {
      this.$emit("rowClicked", order)
    },
    reset() {
      this.loaded = false
      this.error = ''

    },

    dismissPopover() {
      this.$root.$emit('bv::hide::popover')
    },


  }
}
</script>

<style scoped>

</style>
