<template>
  <div class="realEstate-page">
    <div v-if="loaded === true">
      <!-- RealEstate statistics -->
      <b-row v-if="isAdmin">
        <b-col cols="12" class="col-12">
          <b-card no-body>
            <b-card-body>
              <div class="d-flex">
                <feather-icon icon="PackageIcon" size="19"/>
                <h4 class="mb-2 ml-50">
                  {{ msg('Copy admin features').toUpperCase() }}
                </h4>
              </div>
              <b-row class="mt-1">
                <b-col cols="12" md="6" lg="3">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="buid"
                      placeholder="Branch"
                      :options="allBranches"
                      class="w-100"
                      label="name"
                      :reduce="(branch) => branch.id"
                      :clearable="false"

                  />
                </b-col>

                <b-col cols="12" md="6" lg="3">
                  <b-button class="text-nowrap" variant="primary" v-b-tooltip
                            @click="copyBank">
                    <i class="far fa-file-excel mr-1"></i>
                    <span>{{ msg('Copy to chosen branch') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <!--Managemnt details -->
        <b-col cols="12" class="col-12">
          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                <div class="d-flex">
                  <feather-icon icon="PackageIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Company details').toUpperCase() }}
                  </h4>
                </div>
                <bank-management v-model="bank" @change="()=>bankUpdated=true"/>
              </div>
            </b-card-body>
          </b-card>
        </b-col>


        <!--Managemnt details -->
        <b-col v-if="bank.buildingMortgages!=null" cols="12" class="col-12">
          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                <div class="d-flex">
                  <feather-icon icon="PackageIcon" size="19"/>
                  <h4 class="mb-2 ml-50">
                    {{ msg('Volume').toUpperCase() }}
                  </h4>
                </div>
                <bank-mortgages v-model="bank" @change="()=>bankUpdated=true"/>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <!--Managemnt details -->
        <b-col v-if="bank.rejectedProjects!=null" cols="12" class="col-12">
          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                <div class="d-flex">
                  <feather-icon icon="PackageIcon" size="19"/>
                  <h4 class="mb-2 ml-50">
                    {{ msg('Rejected projects').toUpperCase() }}
                  </h4>
                </div>
                <rejected-projects v-model="bank" @change="()=>bankUpdated=true"/>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

      </b-row>


      <div class="update-realEstate" v-bind:class="{'active':(bankUpdated===true)}">
        <b-button variant="primary" class="mx-1" @click="updateBank">{{ msg("Save") }}</b-button>
        <b-button variant="warning" @click="loadBank">{{ msg("Cancel") }}</b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-items-center" style="min-height: 600px">
        <div class="d-inline-block w-100 text-center">
          <b-spinner class="mx-1" variant="primary"/>
          <strong>{{ msg('Loading') }}...</strong>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import BankManagement from '@/views/bank/bankManagement'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import BankMortgages from "@/components/panels/bank/bankMortgages";
import RejectedProjects from "@/components/panels/bank/rejectedProjects";


export default {
  name: 'editBank',
  components: {
    RejectedProjects,
    BankManagement,
    BankMortgages,
  },
  data() {
    return {
      loaded: false,
      error: null,
      buid: null,
      bank: {
        name: '',


      },
      dialogs: {
        client: false,
      },
      bankUpdated: false,
      //Listeners
      listeners: {
        submit: () => {
        }
      },
    }
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    },
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('user', ['myAdminStatus', 'myRoleId']),
    ...mapGetters('data', ['getAllLanguage', 'getAllBranches', 'getAllCountries', 'getAdminRoleId']),
    canSubmit: {
      get() {
        return false
      },
      save(v) {
        this.$emit('update:canSave', v)
      }
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    isAdmin() {

      return this.myRoleId === 1;
    }
  },
  created() {
    if (this.$route.params.id == null) {
      return this.error = 'Invalid bank id'
    }
    this.id = this.$route.params.id;
    this.onLoad()
  },

  methods: {
    ...mapActions('bank', {_getBank: 'getBankById', _updateBank: 'updateBank', _copyBank: 'copyBank'}),
    copyBank() {

      this._copyBank({bank: this.bank.id, buid: this.buid})
    },
    onLoad() {
      this.loaded = false

      this.loadBank()

    },

    loadBank() {
      let $this = this
      $this.loaded = false
      this._getBank($this.id)
          .then((bank) => {

            $this.bank = bank
            //Set page as loaded
            $this.loaded = true
            $this.bankUpdated = false;
          }, (error) => {
            $this.error = error
          })
    }
    ,

    updateBank() {
      let $this = this
      this._updateBank(this.bank)
          .then(
              (a) => $this.$emit('submitted', {
                status: true,
                server: a
              }),
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },
    validate(field) {
      return this.$v.bank[field] != null && this.$v.bank[field].$error === true
    },
    submit() {
      if (this.id !== 0) {
        return this.updateBank()
      }
      return this.createBank()

    },


  },

}
</script>

<style>
.realEstate-page .update-realEstate {
  position: fixed;
  bottom: 5%;
  z-index: 100;
  transition: all 0.5s ease;
  right: 79px;
  opacity: 0;
}

.realEstate-page .update-realEstate.active {
  opacity: 1;
}

.realEstate-page .update-realEstate .btn-primary {
  -webkit-box-shadow: 0 1px 20px 1px #7367f0 !important;
  box-shadow: 0 1px 20px 1px #7367f0 !important;
}

.realEstate-page .update-realEstate .btn-warning {
  -webkit-box-shadow: 0 1px 20px 1px #ff8510 !important;
  box-shadow: 0 1px 20px 1px #ff8510 !important;
}

.realEstate-page .dz-preview, .dz-file-preview {
  display: none;
}
</style>
