<template>
  <div>
    <b-row class="mt-1">
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Company Name')" label-for="bank-company">
          <b-form-input id="realEstate-sell-price" @change="onValueUpdated" v-model="bank.name"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Address')" label-for="bank-company">
          <b-form-input id="realEstate-sell-price" @change="onValueUpdated"  v-model="bank.address"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Zip')" label-for="bank-company">
          <b-form-input id="realEstate-sell-price" @change="onValueUpdated"  v-model="bank.zip"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('City')" label-for="bank-company">
          <b-form-input id="realEstate-sell-price" @change="onValueUpdated"  v-model="bank.city"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Country')" label-for="bank-company">
          <b-form-input id="realEstate-sell-price" @change="onValueUpdated"  v-model="bank.country"
          />
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Contact Name')" label-for="bank-company">
          <b-form-input id="realEstate-sell-price" @change="onValueUpdated"  v-model="bank.contactName"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Contact Phone')" label-for="bank-company">
          <b-form-input id="realEstate-sell-price" @change="onValueUpdated"  v-model="bank.contactPhone"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Contact Email')" label-for="bank-company">
          <b-form-input id="realEstate-sell-price" @change="onValueUpdated"  v-model="bank.contactEmail"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Volume')" label-for="bank-company">
          <b-form-input id="realEstate-sell-price" @change="onValueUpdated"  v-model="bank.obligation"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group  :label="msg('User')" label-for="bank-company">
          <b-badge class="mt-1" variant="info">{{bank.userId!=null? getUserFullName(bank.userId):'No user' }}</b-badge>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Bookings by region')" label-for="product-barcodes">
          <b-form-tags
              @input="onValueUpdated"
              v-model="bank.region"
              separator=" "
              v-bind:placeholder="msg('Enter regions separated by space')"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Notes')" label-for="bank-company">
          <b-form-textarea  @change="onValueUpdated"  v-model="bank.notes"
          />
        </b-form-group>
      </b-col>
<!--      &lt;!&ndash; Branch &ndash;&gt;
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Branch')" label-for="realEstate-branch">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="bank.buid"
              :options="allBranches"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              :clearable="false"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>-->


    </b-row>  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'bankManagement',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    ...mapGetters('data', ['getOtherUsers', 'getUsersNotByRole', 'getUsersByRole', 'getUserFullName', 'getUserColor', 'getUserAcronyms', 'getAllLanguage', 'getAllCountries', 'getAllUsers', 'getAllBranches', 'isEnergyOrg', 'isCompanyClients']),


    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatuses() {
      return Object.values(this.allStatus)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    bank: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {

    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
